<template>
  <div class="d-flex h-100">
    <Menu v-if="userStore?.isConnected"/>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
import Menu from "@/components/Menu.vue";
import {useUserStore} from "@/stores/user";
import {Tooltip} from 'bootstrap'

export default {
  name: "App",
  components: {Menu},
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
      html: true,
      trigger: 'hover'
    });
  }
}
</script>
<style lang="scss">
@import "./styles/bootstrap";

html, body {
  margin: 0;
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}

.text-center {
  text-align: center;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: #F5F5F5;
}

h1 {
  margin-top: 0;
  font-size: 24px;
  font-weight: 700;
}

h2 {
  margin-top: 0;
  font-size: 18px;
  font-weight: 700;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

table {
  text-align: left;
  border-collapse: collapse;

  tr {
    .d-block-hover {
      visibility: hidden;
    }

    &:hover .d-block-hover {
      visibility: visible;
    }
  }

  tr:not(:last-child) th,
  tr:not(:last-child) td {
    border-bottom: 1px solid #E9E9E9;
  }

  tr th,
  tr td {
    padding: 10px 15px;

    ul {
      margin: 0;
    }
  }

  td {
    color: #5e5e5e;
  }
}

label {
  display: block;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer;
}

.form-control,
input[type=search],
input[type=text],
input[type=email],
input[type=url],
input[type=number],
input[type=phone],
input[type=password] {
  -webkit-appearance: none;
  border: 1px solid #E9E9E9;
  border-radius: 24px;
  width: 100%;
  line-height: 20px;
  padding: 8px 20px;
  box-sizing: border-box;
  font-size: 15px;
}

textarea,
select {
  font-family: inherit;
  font-size: 15px;
  border: 1px solid #E9E9E9;
  border-radius: 24px;
  width: 100%;
  padding: 10px 15px;
  color: #5e5e5e;
  box-sizing: border-box;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: .6em;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  padding-right: 25px;
}

.tox-tinymce {
  border: 1px solid #E9E9E9 !important;
}

.btn {
  border-radius: 50px;
  font-weight: 500;
  padding: 8px 25px;
  line-height: 1.4rem;
}

table {
  width: 100%;
}

#dashboard {
  width: 100%;
  padding: 30px;
  margin: 0 0 0 70px;
}

.white-frame {
  padding: 20px;
  background-color: white;
}

.half {
  width: calc(50% - .5rem);
}
</style>
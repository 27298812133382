import {createRouter, createWebHistory} from 'vue-router'

import Login from "@/views/Login.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {authRequired: true},
        component: () => import( '../views/Dashboard.vue')
    },
    {
        path: '/products',
        meta: {authRequired: true},
        children: [
            {
                path: '',
                name: 'Produits',
                component: () => import( '../views/Products.vue'),
            },
            {
                path: 'new',
                name: 'Nouveau produit',
                component: () => import( '../views/Product.vue'),
            },
            {
                path: ':id',
                name: 'Edition',
                component: () => import( '../views/Product.vue'),
            },
        ]
    },
    {
        path: '/email',
        name: 'email',
        meta: {authRequired: true},
        component: () => import( '../views/Email.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});


router.beforeEach((to, from, next) => {
    const authRequired = to.meta.authRequired === true;
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        return next('/');
    }
    next();
});


export default router

import {defineStore} from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        return {user, token};
    },
    getters: {
        isConnected() {
            return this.user && this.user.id !== undefined;
        }
    },
    actions: {
        setToken(token) {
            this.token = token;
            localStorage.setItem('token', JSON.stringify(token));
        },
        setUser(user) {
            this.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        logout() {
            this.user = null;
            this.token = null;
            localStorage.removeItem('user');
            localStorage.removeItem('token');
        },
    },
})